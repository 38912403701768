@font-face {
  font-family: "ToyotaType-Regular";
  src: url("./assets/fonts/ToyotaType-Regular.ttf");
}

@font-face {
  font-family: "ToyotaType-Bold";
  src: url("./assets/fonts/ToyotaType-Bold.ttf");
}

a {
  text-decoration: none !important;
  color: black !important;
}

#root {
  min-height: 100vh;
  display: flex;
  font-family: "ToyotaType-Regular";
}

.row {
  margin: 0px;
  padding: 0px;
}

.pro-sidebar {
  height: auto !important;
  text-align: center !important;
}

.pro-sidebar.md {
  min-width: 250px;
  width: 250px;
  position: fixed;
  right: 0;
  height: 100vh !important;
  border: 1px solid lightgray;
}

.pro-sidebar.collapsed {
  width: 0px !important;
  min-width: 0px !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-content {
  background-color: white;
  color: black;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: 1px solid rgba(173, 173, 173, 0.2);
  background-color: white;
}

.fondo-chevron-rojo {
  background-color: gray !important;
  transition: background-color ease-in-out 0.9s;
}

.fondo-chevron-negro {
  background-color: lightgray !important;
  transition: background-color ease-in-out 0.9s;
}

.breadcrumb {
  background-color: white !important;
  padding: 0px !important;
}

.expandCard:hover {
  transform: scale(1.2);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cardShadow:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.cardHome {
  margin: 50px 0px 50px 0px;
  transition: 0.3s;
  margin-left: 30px !important;
}

.cardCustombackground {
  background-color: white !important;
}

.hideButtons {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
}

.showButton:hover .hideButtons {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.changeColor:hover {
  color: red;
  transition: color 300ms;
}

.cropped {
  width: 100%; /* width of container */
  height: 55vh; /* height of container */
  object-fit: cover;
  object-position: 40% 50%;
}

#wrapper .text {
  position: relative;
  bottom: 0px;
  left: 7px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 300ms, opacity 300ms;
  font-size: 12px;
}

#wrapper:hover .text {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms;
}

.circleDivs {
  width: 11px;
  height: 11px;
  border-radius: 15px;
}

#modalSize {
  margin: 0;
  padding: 0;
  max-width: 100% !important;
  max-height: 100% !important;
  overflow: hidden;
}

#imageContainer {
  display: inline-block;
  overflow: hidden; /* clip the excess when child gets bigger than parent */
}
#imageContainer img {
  display: block;
  transition: transform 0.4s; /* smoother zoom */
}
#imageContainer:hover img {
  transform: scale(1.08);
  transform-origin: 50% 50%;
}

.textContainer {
  position: relative;
  text-align: center;
  color: white;
}

.textCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.grow {
  padding: 5px 5px 5px 5px;
  border-radius: 10px;
  height: 200px;
  width: 90%;
  margin: 5px 1% 5px 1%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  text-align: center;
  overflow: hidden;
  background-color: rgba(39, 39, 44, 0.466);
}

.grow:hover {
  height: 300px;
  background-color: rgba(0, 0, 0, 0.863);
}

.cubeContainer {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.card-link-action {
  margin-top: 10px;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.card-link-action:hover {
  margin-left: 3px;
}

/* ------- Media queries ------- */

@media (max-width: 768px) {
  .pro-sidebar.md {
    height: 100vh !important;
  }
}

@media (min-width: 1000px) {
  .lateral {
    padding: 0px !important;
    overflow: hidden !important;
  }

  .chevronDer {
    position: absolute;
    right: 40px;
  }

  .chevronIzq {
    position: absolute;
    left: 40px;
  }
}

@media (min-width: 0px) and (max-width: 999px) {
  .lateral {
    padding: 5px !important;
    overflow: hidden !important;
  }

  .chevronDer {
    display: none !important;
  }

  .chevronIzq {
    display: none !important;
  }

  .card {
    margin: 50px 0px 50px 0px;
    transition: 0.3s;
    margin-left: 15px !important;
  }

  #modalSize {
    margin-top: 50% !important;
  }
}
